import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetCss: String,
  }

  connect() {
    if (this.targetCssValue) {
      this.element.querySelector(this.targetCssValue).click();
    } else {
      this.element.focus();
    }
  }
}
