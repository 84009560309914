import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";
// Connects to data-controller="clears--form"
const CHARTS = {}
export default class extends Controller {
  static values = {
    labels: Array,
    data: Array,
    chartId: String,
    chartType: String,
    images: Array
  }

  connect() {
    const max = Math.max(...this.dataValue)
    if (!CHARTS[this.chartIdValue]) {
      const ctx = document.getElementById(this.chartIdValue)
      this.chart = new Chart(ctx, {
        type: this.chartTypeValue,
        data: {
          labels: this.labelsValue,
          datasets: [{
            data: this.dataValue,
            borderWidth: 1,
          }],
          images: this.imagesValue
        },
        options: {
          indexAxis: 'y',
          animation: {
            delay: 100
          },
          scales: {
            x: {
              max: Math.ceil(max / 100) * 100,
            },
            y: {
              afterFit: function(scale) {
                scale.width = 100
            }}
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              text: 'Most used operators',
              display: true,
              font: {
                size: 20
              }
            }
          }
        }
      })
      CHARTS[this.chartIdValue] = this.chart
    } else {
      this.chart = CHARTS[this.chartIdValue]
      const originalMapping = this.chart.data.labels.reduce((acc, value, index) => {
        acc[value] = index
        return acc
      }, {})
      this.chart.data.labels = this.labelsValue
      this.chart.data.images = this.imagesValue
      this.chart.data.datasets[0].data = this.dataValue
      // const meta =this.chart.getDatasetMeta(0)
      // console.log(meta.data)
      // const newMetaData = []
      // this.labelsValue.forEach((label, index) => {
      //   newMetaData[index] = meta.data[originalMapping[label]]
      // })

      // meta.data =newMetaData
      this.chart.update()
    }
  }
}
