import { Controller } from "@hotwired/stimulus";
// Connects to data-controller="clears--form"
export default class extends Controller {

  markExclude(event) {
    if (event.submitter.dataset.exclude) {
      this.excluded = true
    } else {
      this.excluded = false
    }
  }
  handleExclude(event) {
    if (this.excluded) {
      event.formData.set('used_operator[excluded]', 'true')
    }
  }
}