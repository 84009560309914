import { Controller } from "@hotwired/stimulus";
import YoutubePlayer from "youtube-player";
export default class extends Controller {
  static values = {
    link: String,
    initialSeek: Number,
  };
  static targets = ["linkInput"]

  connect() {
    const player = YoutubePlayer(this.element.querySelector('#video-player').id)
    const url = new URL(this.linkValue);
    const videoId = new URLSearchParams(url.search).get("v")  || url.pathname.split("?")[0].split("/").pop();
    this.currentSeek = this.initialSeekValue
    player.loadVideoById(videoId, this.currentSeek || 0);
    player.playVideo();
    player.on("stateChange", (event) => {
      if (event.data === 1) {
        player.pauseVideo();
      }
    })
    this.player = player
  }

  disconnect() {
    if (this.player) this.player.destroy()
  }

  seekPrev() {
    this.seek(-1)
  }

  seekNext() {
    this.seek(1)
  }

  seekSkip() {
    this.seek(5)
  }

  seek(second) {
    const player = this.player
    if (!player) return;

    this.currentSeek += second
    player.seekTo(this.currentSeek, true);
    player.playVideo();
  }

  openStageSlect() {
    const element = this.element.querySelector('label[for="extract_clear_data_from_video_job_stage_id"]')
    element.click();
    element.scrollIntoView();
  }

  assignCurrentTimestamp() {
    const url = new URL(this.linkInputTarget.value);
    const videoId = new URLSearchParams(url.search).get("v");
    this.linkInputTarget.value = `https://youtube.com/watch?v=${videoId}&t=${this.currentSeek}s`
  }

  triggerSubmit() {
    this.element.querySelector('form').submit();
  }
}