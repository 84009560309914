  import { Controller } from "@hotwired/stimulus";
// Connects to data-controller="clears--form"
export default class extends Controller {
  static targets = ["update"];

  setAttr(event) {
    const targets = this.elementsToBeUpdated(event);
    if (
      !event.params ||
      !event.params.name ||
      targets.length === 0 ||
      event.params.value === undefined
    )
      return;
    targets.forEach((target) => {
      target[event.params.name] = event.params.value;
    });
  }

  setAttrOnSelect(event) {
    if (
      !event.params ||
      !event.params.name ||
      event.params.value === undefined ||
      !event.detail
    )
      return;
    if (!event.detail.value && event.params.blankValue !== undefined) {
      event.params.value = event.params.blankValue;
    }
    this.setAttr(event);
  }

  elementsToBeUpdated(event) {
    return (
      (this.hasUpdateTarget && this.updateTargets) ||
      document.querySelectorAll(event.params.targetCss)
    );
  }
}