import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    newClass: Array,
  }
  static targets = ["changed"];

  addClass() {
    console.log(this.newClassValue)
    if (this.hasChangedTarget) {
      this.changedTargets.forEach((target, index) => target.classList.add(...this.newClassValue[index].split(' ')));
    } else {
      this.element.classList.add(...this.newClassValue[0].split(' '));
    }
  }
}