import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    frameId: String,
  };

  connect() {
    this.initListeners();
  }

  initListeners() {
    this.onFrameRenderHandler = this.onFrameRender.bind(this);
    this.onCloseHandler = this.onClose.bind(this);
    this.onFormSubmitHandler = this.onFormSubmit.bind(this);

    document.addEventListener("turbo:frame-render", this.onFrameRenderHandler);
    this.modal.addEventListener("close", this.onCloseHandler);
    this.modal.addEventListener("turbo:submit-end", this.onFormSubmitHandler);
  }

  disconnect() {
    this.cleanUpListeners();
  }

  cleanUpListeners() {
    document.removeEventListener(
      "turbo:frame-render",
      this.onFrameRenderHandler
    );
    this.modal.removeEventListener("close", this.onCloseHandler);
    this.modal.removeEventListener(
      "turbo:submit-end",
      this.onFormSubmitHandler
    );
  }

  get modal() {
    return this.element;
  }

  get modalFrame() {
    return this.modal.querySelector(this.frameSelector);
  }

  get frameSelector() {
    return `#${this.frameIdValue}`;
  }

  onFrameRender(event) {
    const frame = event.target;
    if (frame.id === this.frameIdValue) {
      this.showModal();
    }
  }

  onClose() {
    setTimeout(() => {
      this.cleanUp();
    }, 100); // avoid flicker
  }

  onFormSubmit() {
    this.closeModal();
    this.cleanUp();
  }

  cleanUp() {
    this.modalFrame.innerHTML = "";
  }

  showModal() {
    this.modal.showModal();
  }

  closeModal() {
    this.modal.close();
  }
}
